(function($) {
    document.addEventListener('DOMContentLoaded', function() {

        /**
         * Faq
         */
        const faqs = document.querySelectorAll('.faq-section__item');
        if (faqs.length) {
            let canToggle = true;

            for (let i = 0; i < faqs.length; i++) {
                const heading = faqs[i].querySelector('.faq-section__item-heading');
                if (heading) {
                    heading.addEventListener('click', faqHandler);
                }
            }

            function faqHandler() {
                const heading = this;
                const content = heading.parentElement.querySelector('.faq-section__item-content');

                if (!heading.classList.contains('active')) {
                    resetFAQActive();

                    heading.classList.add('active');
                    $(content).slideDown(300, function () {
                        canToggle = true;
                        console.log(canToggle);
                    });
                } else {
                    resetFAQActive();
                }

                canToggle = false;
            }

            function resetFAQActive() {
                if (canToggle) {
                    for (let i = 0; i < faqs.length; i++) {
                        const heading = faqs[i].querySelector('.faq-section__item-heading');
                        const content = heading.parentElement.querySelector('.faq-section__item-content');

                        if (heading && heading.classList.contains('active')) {
                            heading.classList.remove('active');
                            $(content).slideUp();
                        }
                    }
                }
            }
        }
    });
})(jQuery);